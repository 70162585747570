@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/MaterialIcons-Regular.ttf');
  }
  @keyframes pulse {
    0%, 100% {
      color: #FE5000;
    }
    50% {
      color: #fe5000c0;
    }
  }
*{
    padding:0;
    margin:0;
}


.logo{
    min-height:40vh;
    background-image: url('../img/logo.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size:contain;
}

body{
    background-color: black !important;
}

h1, .source{
    color:white !important;
}

#playButton{
    width:55px;
    height:55px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    border:none;
    margin: 5px 15px 5px 5px;
    float:left;
    font-family: 'Material Icons';
    color:#FE5000;
    font-size:40px;
    line-height:0px;
    background-color: black;
}

#playButton:active{
    animation: pulse 20s infinite;
}

.card{
    margin: 15px auto 15px auto;
    padding:5px;
    background: linear-gradient(to bottom, #0f0f0f 0%,#323232 50%,#141414 51%,#282828 100%);
    box-shadow: 2px 2px 7px #000;
}

input[type="range"]{
    width:100%;
    height:10px;
    accent-color:#FE5000;
    outline:none;
    margin:7px 0px 0px 0px;
    background-color: #FE5000;
}

.timeline{
    width:100%;
    margin:0px 5px 0px 0px;
}

p{
    margin:0px;
}

.time{
    background-color: black;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:0px 5px 0px 5px;
    color:rgba(255,255,255,0.6);
}

.artist{
    text-transform: uppercase;
    font-weight: bolder;
    color:rgba(255,255,255,0.6);
}

.title{
    font-variant: small-caps;
    color:#FE5000;
    animation: pulse 2s infinite;
}

.spacer{
    color:rgba(255,255,255,0.4);
    margin:0px 15px 0px 15px;
}

.details{
    margin-top:7px;
}

.light{
    background-color: #070707;
}

.container-fluid{
    margin: 0px;
    padding: 0px;
}

.btn-warning{
    background-color: #FE5000;
    border: 1px solid #FE5000;   
    color:#fff;
}

.btn-warning:hover{
    background-color: #fe5000c0;
    border: 1px solid #fe5000c0;
    color:#fff;   
}

.btn-warning:focus{
    background-color: #fe5000c0;
    border: 1px solid #fe5000c0;
    color:#fff;
}

.light{
    background-color: #101010;
}